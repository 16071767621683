import { useLocation } from "react-router-dom";

import useBills from "features/bills/api/bills.api";
import Button from "features/ui/forms/button";
import SkeletonComponent from "features/ui/feedbacks/loading-skeleton";
import BillCard from "features/bills/components/bill-card";
import Header from "features/ui/layout/headers/dashboard-header";
import TabSelect from "features/ui/layout/nav/tabselect";

export default function BillAccounts() {
   const { pathname } = useLocation();
   const { bills, getAllBillsLoading } = useBills();
   const tabSelectOptions = [
      {
         title: "Sub Accounts",
         href: "/dashboard/bill-accounts",
         isActive: pathname.includes("bill-accounts"),
      },
      {
         title: "Savings",
         href: "/dashboard/savings-accounts",
         isActive: pathname.includes("savings-accounts"),
      },
   ];
   return (
      <section className="wrapper min-h-screen flex flex-col items-center justify-start w-[5/6] pb-20">
         <Header title="Accounts" variant="ACCOUNTS" />

         <div className="w-full  text-primary md:max-w-xl">
            <div className="self-stretch justify-center px-[20px] w-full  items-center flex flex-col gap-5">
               <TabSelect options={tabSelectOptions} />
               {getAllBillsLoading && <SkeletonComponent />}

               {bills?.map((bill) => (
                  <BillCard key={bill._id} bill={bill} />
               ))}

               <div className="mt-10 col-span-2 block">
                  <Button
                     size="WIDE"
                     color="BLUE"
                     // onClick={createBillModalOnOpen}
                     to="/dashboard/bill-accounts/create"
                  >
                     Create Bill
                  </Button>
               </div>
            </div>

            {/* <CreateBill
               isOpen={createBillModalIsOpen}
               onClose={createBillModalOnClose}
            /> */}
         </div>
      </section>
   );
}
