import { useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";

import SelectIcon from "./select-icon";
import { useOutsideClick } from "utils/hooks/useOutsideClick";
import { useRef } from "react";

export default function Select({
   lineSelect,
   label,
   value,
   error,
   options,
   handleChange,
   variant = "SINGLE",
   renderSelected,
}) {
   const [isOpen, setIsOpen] = useState(false);

   const handleSelect = (option) => {
      if (option.disabled) {
         return;
      }
      const value = option.value ?? option.name;
      handleChange(value);
      variant !== "MULTIPLE" && setIsOpen(false);
   };

   const inputRef = useRef(null);
   useOutsideClick(inputRef, () => setIsOpen(false));

   //styles
   const dropDownHeaderStyle = `
      bg-white
      border-grey-400 
      rounded-sm
      w-full p-[14px] 
      flex justify-between items-center 
      text-base capitalize text-secondary-darker
      ${lineSelect ? "border-b-2" : "border-2 shadow-inner rounded-lg"} 
   `;
   const dropDownStyle = `
      absolute z-[2] right-0
      bg-white 
      overflow-y-scroll
      border-2 rounded-sm w-4/6 max-h-[270px] 
      ${isOpen ? "block" : "hidden"}     
      md:max-h-[130px]
   `;
   const errorClass = `
      whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder,
      ${variant === "LINE" && "absolute -top-4 pl-0"}
   `;

   const getDropDownText = (label, value) => {
      if (!value || value.length === 0) {
         return label;
      } else if (variant === "MULTIPLE") {
         const selected = options.filter((i) => {
            return value.includes(i.value ? i.value : i.name);
         });
         return selected.map((i) => i.name).join(", ");
      } else {
         const selected = options.find((i) => {
            return value === (i.value ? i.value : i.name);
         });
         return selected ? selected.name : label; // Handle case when selected is undefined
      }
   };

   return (
      <>
         <div ref={inputRef} className="relative cursor-pointer mt-[14px] min-w-[170px]">
            <div className={dropDownHeaderStyle} onClick={() => setIsOpen((prev) => !prev)}>
               <div>
                  {renderSelected ? renderSelected(label, value) : getDropDownText(label, value)}
               </div>
               <BsCaretDownFill
                  className={`${isOpen && "-rotate-180"} text-primary  transition-all duration-500`}
               />
            </div>

            <div className={dropDownStyle}>
               <ul>
                  {options?.map((option) => (
                     <li
                        tabIndex={1}
                        key={option.name}
                        className={`${getColor(
                           option,
                           variant,
                           value
                        )} p-2 capitalize  text-primary flex justify-between items-center`}
                        onClick={() => handleSelect(option)}
                     >
                        <p>{option.name}</p>
                        <SelectIcon
                           variant={variant}
                           src={option?.src}
                           icon={option?.icon}
                           isSelected={isSelected(option, variant, value)}
                        />
                     </li>
                  ))}
               </ul>
            </div>
            <p className={errorClass}>{error?.message}</p>
         </div>
      </>
   );
}

const isSelected = (option, variant, value) => {
   if (variant === "MULTIPLE") {
      const item = option.value ?? option.name;
      return value.includes(item);
   } else {
      return value === option.name || value === option.value;
   }
};

const getColor = (option, variant, value) => {
   if (option.disabled) {
      return "text-gray-300";
   }

   if (isSelected(option, variant, value)) {
      return "text-yellow-500";
   }

   return "";
};
