import { Link } from "react-router-dom";

import AccountIcon from "../../../assets/icons/LedgaV2 Icons/folder-gray.png";

export default function MemberCard({ member }) {
   return (
      <div className="flex flex-col items-center">
         <div className="group bg-white flex flex-row justify-start px-5 md:px-10 gap-4 items-center text-black-three relative  rounded-xl w-full md:py-[10px] max-w-full md:max-w-full">
            <Link
               to={`/dashboard/members/${member._id}`}
               className="after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0"
            ></Link>
            <img src={AccountIcon} alt="icon" className="text-black-one h-[40px]" />
            <div className="text-start capitalize text-base p-5 font-semibold md:text-lg md:py-6">
               <p className="font-normal poppins-bold text-[16px] text-black-one">{member.name}</p>
               <p className="font-normal poppins-medium text-[16px]">{member.unique_id}</p>
            </div>
         </div>
         <hr className="w-1/2 text-gray-200" />
      </div>
   );
}
